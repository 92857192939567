import gsap from 'gsap'
const bar = document.querySelector(".loading__bar--inner");
const counter_num = document.querySelector(".loading__counter--number");
const loading_bar = document.querySelector(".loading__bar");
let c = 0;
 
let barInterval=setInterval(() => {
    bar.style.width = c + "%";
    counter_num.innerText = c+ "%";
    c++;
    if(c === 101) {
        clearInterval(barInterval);
        gsap.to(".loading__bar", {
            duration: 3,
            rotate: "360deg",
            left: "270%",
        })
        gsap.to(".loading__text, .loading__counter", {
            duration: 1,
            opacity: 0
        })
        gsap.to(".loading__box", {
            duration: 2,
            height: '500px',
            borderRadius: "50%"
        })
        gsap.to(".loading__svg", {
            duration: 10,
            opacity: 1,
            rotate: "360deg"
        })
        gsap.to(".loading__box", {
            delay: 2,
            border: 'none',
        })
        gsap.to(".loading", {
            delay: 2,
            duration: 2,
            background: "transparent",
            opacity: 1,
            zIndex:1
        })
        gsap.to(".loading__svg", {
            delay: 2,
            duration: 100,
            rotate: "360deg"
        })
    }
}, 10)